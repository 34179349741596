<template>
  <footer class="footer bg-light">
    <p class="float-end"><a href="#">Back to top</a></p>
    <div class="row">
      <div class="col-12 col-md-4">
        <p>
          © 2021-2023 Round Here, LLC. ·
          <a href="https://roundhere.co/rh/privacy">Privacy</a> ·
          <a href="https://roundhere.co/rh/terms">Terms</a>
        </p>
      </div>
      <div class="col-12 col-md-4 text-center">
        <a href="https://roundhere.co">Home</a> ·
        <a href="https://invest.roundhere.co">Invest</a> ·
        <a href="https://raise.roundhere.co">Raise</a> ·
        <a href="https://roundhere.co/rh/kb">Knowledge Base</a> ·
        <a href="https://raise.roundhere.co/contact">Contact</a>
      </div>
    </div>
    <p>
      BEFORE INVESTING, INVESTORS MUST RELY ON THEIR OWN EXAMINATION OF EACH
      INVESTMENT AND ITS TERMS, MERITS, AND RISKS. INVESTMENTS ON THIS FUNDING
      PORTAL ARE SPECULATIVE, ILLIQUID, AND INVOLVE A HIGH DEGREE OF RISK,
      INCLUDING THE RISK OF LOSS OF YOUR INVESTMENT. INVESTORS SHOULD VERIFY ANY
      ISSUER INFORMATION THEY CONSIDER IMPORTANT BEFORE MAKING AN INVESTMENT.
    </p>
    <p>
      This site ("Portal") is owned and maintained by Round Here, LLC (“Round
      Here” or “we”), which is a funding portal but not a registered
      broker-dealer. No securities offered on the Portal have been recommended
      or approved by any federal or state securities commission or regulatory
      authority. We do not give investment advice, or recommend or endorse any
      particular investment or securities. All securities listed on the Portal
      are offered by the issuer of the securities, and all information included
      on this Portal is the responsibility of the relevant issuer. Any
      intermediary facilitating an offering will be identified in such
      offering’s documentation.
    </p>
    <p>
      All funding portal activities are conducted by Round Here, which is a
      funding portal registered as such with the US Securities and Exchange
      Commission (SEC). Round Here is a member of the Financial Industry
      Regulatory Authority (FINRA). Round Here’s office address is 1905 Huguenot
      Road, Suite 200, Richmond, Virginia 23235. More information is on
      <a
        href="https://www.finra.org/about/firms-we-regulate/funding-portals-we-regulate"
        >FINRA’s Funding Portal page</a
      >
    </p>
    <p>
      The Portal and Round Here web site include educational and reference
      materials regarding the Portal and the types of securities offered on the
      Portal. We do not verify information presented by companies featured on
      the Portal, and we cannot assure potential investors of the completeness
      or accuracy of any such information. Information about companies on the
      Portal is located on each company’s offering page and on the documentation
      that is linked from that page. Additional information may be found by
      searching the
      <a href="https://www.sec.gov/edgar/searchedgar/companysearch.html"
        >EDGAR database</a
      >
      for companies who have made such filings, or by searching the records of
      the secretary of state or company registrar for the state of each
      company’s formation.
    </p>
    <p>
      No communication on or through the Portal or by any means or elsewhere
      should be construed as a recommendation for any security offered.
      Investment offerings on this Portal are private-placement securities that
      involve a high degree of risk, may lose value, are not publicly traded,
      are subject to holding period requirements, and are intended for investors
      who do not need a liquid investment. Past performance is not indicative of
      future results. Investors must be able to afford to lose the investment.
      Only qualified investors may invest in offerings on the Portal.
    </p>
    <p>
      Neither Round Here nor any officer, director, agent, employee, or
      representative of Round Here makes any warranty of any kind, express or
      implied, related to the adequacy, accuracy or completeness of any
      information on the Platform. All offers of securities on the Portal can
      only be made through official offering documents that contain important
      information about the investment and the issuers, including risks.
      Investors should carefully read all offering documents before investing,
      and should consult their own legal, financial, business, and tax advisors.
    </p>
    <p>
      By accessing the Portal and any of its pages and offerings hosted on the
      Portal, you agree to be bound by Round Here’s Terms of Use and Privacy
      Policy. Please also see our Business Continuity Plan and Additional Risk
      Disclosures. The list of our active offerings
      <router-link :to="{ name: 'Offerings' }">here.</router-link>
    </p>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
footer {
  padding: 20px;
  margin-bottom: 100px;
}
</style>
