import { ref } from 'vue';
import { projectAuth } from '../firebase/config';

// refs
const user = ref(projectAuth.currentUser);
const issuer = ref(false);

// auth changes
projectAuth.onAuthStateChanged(_user => {
  user.value = _user;
  if (user.value) {
    user.value.getIdTokenResult().then((getIdTokenResult) => {
      if (getIdTokenResult.claims.issuer) {
        issuer.value = true;
      } else {
        issuer.value = false;
      }
    });
  }
});

// set role
const setIssuer = (value) => {
  issuer.value = value;
};

const getUser = () => {
  return { user, issuer, setIssuer };
};

export default getUser;