<template>
  <main>
    <form @submit.prevent="handleSubmit" class="shadow">
      <h3>Issuer Sign In</h3>
      <div class="form-outline mb-4">
        <input
          type="email"
          v-model="email"
          placeholder="Email Address"
          class="form-control"
        />
      </div>
      <div v-if="isPasswordReset">
        <span class="btn btn-primary" @click="sendPasswordReset"
          >Reset Password</span
        >
        <div class="mt-4 inline">
          <span class="btn btn-link btn-sm" @click="togglePasswordReset(false)"
            >I remembered my password</span
          >
        </div>
      </div>
      <div v-else>
        <div v-if="isPasswordShowing" class="input-group mb-4">
          <input
            type="text"
            placeholder="Password"
            v-model="password"
            class="form-control"
          />
          <span
            class="input-group-text btn btn-primary"
            @click="isPasswordShowing = false"
            ><i class="bi bi-eye-slash"></i>
          </span>
        </div>
        <div v-else class="input-group mb-4">
          <input
            type="password"
            placeholder="Password"
            v-model="password"
            class="form-control"
          />
          <span
            class="input-group-text btn btn-primary"
            @click="isPasswordShowing = true"
            ><i class="bi bi-eye"></i>
          </span>
        </div>
        <div
          v-if="!isPending"
          class="d-flex justify-content-between align-items-center"
        >
          <button class="btn btn-primary btn-block">Sign In</button>
          <div>
            Not a member?
            <router-link
              :to="{ name: 'SignUp', query: { from: route.query.from } }"
              class="float-end ms-1"
            >
              Sign Up</router-link
            >
          </div>
        </div>
        <button v-else class="btn btn-secondary btn-block" disabled>
          <div class="spinner-border" role="status"></div>
        </button>
        <div class="mt-4 inline">
          <span class="align-middle">Forgot your password?</span>
          <span class="btn btn-link btn-sm" @click="togglePasswordReset(true)"
            >Send a reset email</span
          >
        </div>
      </div>
      <div v-if="formError" class="error">{{ formError }}</div>
    </form>
  </main>
  <Footer />
</template>

<script>
import useLogin from "../../composables/useLogin";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Footer from "@/components/navigation/Footer.vue";

export default {
  components: { Footer },
  setup() {
    const isPasswordReset = ref(false);
    const isPasswordShowing = ref(false);
    const formError = ref(null);
    const { error, login, resetPassword, isPending } = useLogin();
    const router = useRouter();
    const route = useRoute();
    const email = ref("");
    const password = ref("");

    const togglePasswordReset = resetVisible => {
      isPasswordReset.value = resetVisible;
      formError.value = "";
    };

    const handleSubmit = async () => {
      formError.value = null;
      if (isPasswordReset.value) {
        await sendPasswordReset();
      } else {
        await login(email.value, password.value);
        if (!error.value) {
          if (route.query.from) {
            router.push({ path: route.query.from });
          } else {
            router.push({ name: "Offerings" });
          }
        } else {
          formError.value = error.value;
        }
      }
    };

    const sendPasswordReset = async () => {
      formError.value = null;
      await resetPassword(email.value);
      if (!error.value) {
        formError.value =
          "A password reset link has been sent to your email address.";
        isPasswordReset.value = false;
      } else {
        formError.value = error.value;
      }
    };

    return {
      email,
      password,
      isPasswordShowing,
      isPasswordReset,
      sendPasswordReset,
      togglePasswordReset,
      handleSubmit,
      formError,
      isPending,
      route
    };
  }
};
</script>

<style>
</style>