<template>
  <main>
    <div v-if="showingTerms">
      <div v-if="agreedTerms && agreedTermsId && agreedPrivacyId">
        <p class="story-text" v-html="agreedTerms"></p>

        <div
          class="container-fluid fixed-bottom shadow-lg bg-white d-flex justify-content-center"
        >
          <div class="m-3 pt-3">
            <p>
              <b>You have already agreed to these terms and privacy policy.</b>
            </p>
          </div>
          <div class="m-3 pt-2">
            <span class="btn btn-secondary" @click="backToSignUp"
              >Back to Sign Up</span
            >
          </div>
        </div>
      </div>
      <div v-else>
        <h4>
          Please read the Terms and Privacy Policy and click "I Agree" at the
          bottom to sign up
        </h4>
        <div v-if="errorTerms">{{ errorTerms }}</div>
        <div v-for="term in terms" :key="term.id">
          <p class="story-text" v-html="term.details"></p>
          <div v-for="privacyPolicy in privacyPolicies" :key="privacyPolicy.id">
            <div>
              <p class="fw-bold">
                This agreement contains a predispute arbitration clause, in the
                Terms, at part 23.
              </p>
            </div>
            <div class="text-center">
              <span
                class="btn btn-primary"
                @click="acceptTerms(term.id, term.details, privacyPolicy.id)"
                >I Agree</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <form v-else @submit.prevent="handleSubmit" class="shadow">
      <h3>Issuer Sign Up</h3>
      <div class="form-outline mb-4">
        <input
          type="text"
          placeholder="Display Name"
          v-model="displayName"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <input
          type="email"
          placeholder="Email"
          v-model="email"
          class="form-control"
        />
      </div>
      <div v-if="isPasswordShowing" class="input-group mb-4">
        <input
          type="text"
          placeholder="Password"
          v-model="password"
          class="form-control"
        />
        <span
          class="input-group-text btn btn-primary"
          @click="isPasswordShowing = false"
          ><i class="bi bi-eye-slash"></i>
        </span>
      </div>
      <div v-else class="input-group mb-4">
        <input
          type="password"
          placeholder="Password"
          v-model="password"
          class="form-control"
        />
        <span
          class="input-group-text btn btn-primary"
          @click="isPasswordShowing = true"
          ><i class="bi bi-eye"></i>
        </span>
      </div>
      <!-- <div class="form-outline mb-4">
        <input
          type="text"
          placeholder="Sign Up Code"
          v-model="signUpCode"
          class="form-control"
          required
        />
      </div> -->
      <div class="pb-3">
        I have agreed to these
        <span class="btn-link s-link p-0" @click="showingTerms = true">
          Terms and Privacy Policy
        </span>
      </div>
      <div v-if="formError" class="error">{{ formError }}</div>
      <div
        v-if="!isPending"
        class="d-flex justify-content-between align-items-center"
      >
        <button class="btn btn-primary btn-block">Sign Up</button>
        <div>
          Already a member?
          <router-link
            :to="{ name: 'Login', query: { from: route.query.from } }"
            class="float-end ms-1"
          >
            Sign In</router-link
          >
        </div>
      </div>
      <button v-else class="btn btn-secondary btn-block" disabled>
        <div class="spinner-border" role="status"></div>
      </button>
    </form>
  </main>
  <Footer v-if="terms" />
</template>

<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import useSignUp from "@/composables/useSignUp";
// import useFunctions from "@/composables/useFunctions";
import useCollection from "@/composables/useCollection";
import getCollectionStatic from "@/composables/getCollectionStatic";
import getUser from "@/composables/getUser";
import { timestamp } from "../../firebase/config";
import useLogout from "@/composables/useLogout";
import Footer from "@/components/navigation/Footer.vue";

export default {
  components: { Footer },
  setup() {
    const isPasswordShowing = ref(false);
    const showingTerms = ref(true);
    const { error: memberError, addUniqueId } = useCollection("users");
    const agreedTerms = ref(null);
    const agreedTermsId = ref(null);
    const agreedPrivacyId = ref(null);
    const email = ref("");
    const signUpCode = ref("");
    const password = ref("");
    const displayName = ref("");
    const formError = ref(null);
    const isPending = ref(false);

    const router = useRouter();
    const route = useRoute();
    const { error: signUpError, signUp } = useSignUp();
    // const { error: issuerError, issuerApproval } = useFunctions();
    const { setIssuer } = getUser();
    const { logout, error: errorLogout } = useLogout();

    const { error: errorTerms, documents: terms } = getCollectionStatic(
      "rhTerms",
      null,
      ["createdAt", "desc"],
      1
    );
    const {
      error: errorPrivacy,
      documents: privacyPolicies
    } = getCollectionStatic("rhPrivacy", null, ["createdAt", "desc"], 1);

    const handleSubmit = async () => {
      formError.value = "";
      isPending.value = true;
      try {
        // await issuerApproval(signUpCode.value);
        // console.log("SignUp: ", issuerError.value);
        // if (!issuerError.value) {
        const res = await signUp(
          email.value,
          password.value,
          displayName.value
        );
        if (!signUpError.value) {
          await addUniqueId(res.user.uid, {
            role: "issuer",
            name: displayName.value,
            email: email.value,
            agreedToTermsId: agreedTermsId.value,
            agreedToPrivacyId: agreedPrivacyId.value,
            createdAt: timestamp()
          });
          setIssuer(true);
          isPending.value = false;
          if (!memberError.value) {
            //logout
            await logout();
            if (!errorLogout.value) {
              router.push({ name: "Welcome" });
            } else {
              formError.value("Error logging out: ", errorLogout.value);
            }
          } else {
            formError.value = memberError.value;
          }
        } else {
          formError.value = signUpError.value;
        }
        // } else {
        //   console.log("APPROVAL ERROR: ", issuerError.value);
        //   formError.value = "Sign Up Code is Invalid";
        // }
        isPending.value = false;
      } catch (err) {
        formError.value = "Error Signing Up: " + err.message;
        isPending.value = false;
      }
    };

    const acceptTerms = (termsId, terms, privacyPolicyId) => {
      agreedTermsId.value = termsId;
      agreedTerms.value = terms;
      agreedPrivacyId.value = privacyPolicyId;
      showingTerms.value = false;
      window.scrollTo(0, 0);
    };

    const backToSignUp = () => {
      showingTerms.value = false;
      window.scrollTo(0, 0);
    };

    return {
      email,
      password,
      isPasswordShowing,
      displayName,
      signUpCode,
      formError,
      isPending,
      handleSubmit,
      route,
      agreedTerms,
      agreedTermsId,
      agreedPrivacyId,
      acceptTerms,
      errorTerms,
      errorPrivacy,
      terms,
      privacyPolicies,
      showingTerms,
      backToSignUp
    };
  }
};
</script>

<style>
</style>