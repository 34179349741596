<template>
  <header>
    <nav
      class="navbar fixed-top navbar-expand-md navbar-light bg-light shadow-sm"
    >
      <div class="container-fluid">
        <a class="navbar-brand" :href="homePage"
          ><img id="navbar-logo" src="@/assets/img/RoundHere_logo_152x80.png"
        /></a>
        <button
          v-if="user && issuer"
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          v-if="user && issuer"
          class="collapse navbar-collapse"
          id="navbarCollapse"
        >
          <ul class="navbar-nav me-auto mb-2 mb-md-0 d-flex text-center">
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Investors')"
                >Investors</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Offerings')"
                >Offerings</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Account')"
                >Account</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Contact')"
                >Contact</span
              >
            </li>
          </ul>
          <div class="text-center">
            <button class="btn btn-primary" @click="handleClick">
              Sign Out
            </button>
          </div>
        </div>
        <div v-else>
          <a class="nav-link" :href="investURL" style="margin-bottom: 5px"
            >Investor Sign In</a
          >
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import { ref } from "vue";
import useLogout from "@/composables/useLogout";
import { useRouter } from "vue-router";
import getUser from "@/composables/getUser";

export default {
  setup() {
    const { logout, error } = useLogout();
    const investURL = ref(process.env.VUE_APP_INVEST_URL);
    const homePage = ref(process.env.VUE_APP_HOME_PAGE);
    const router = useRouter();
    // const isScrolled = ref(false);
    const { user, issuer } = getUser();
    const handleClick = async () => {
      await logout();
      if (!error.value) {
        router.push({ name: "Login" });
      } else {
        console.log("Error logging out: ", error.value);
      }
    };

    const closeMenuRoute = routeName => {
      router.push({ name: routeName });
    };

    return { handleClick, user, issuer, investURL, homePage, closeMenuRoute };
  }
};
</script>
<style>
/* HEADER / TOP MENU
------------------------------------------------- */

#navbar-logo {
  height: 40px;
}

#navbar-logo.small {
  height: 40px;
}

li.nav-item {
  text-align: center;
  margin-right: 5px;
}

.navbar-expand-md .navbar-nav .nav-link {
  margin-top: 6px;
  color: #404042;
  font-weight: 600;
  text-transform: uppercase;
}
</style>
