import { ref } from 'vue';
import { projectAuth } from '../firebase/config';

const error = ref(null);
const isPending = ref(false);

const signUp = async (email, password, displayName) => {
  error.value = null;
  isPending.value = true;

  try {
    const res = await projectAuth.createUserWithEmailAndPassword(email, password);
    if (!res) {
      throw new Error('Could not complete sign up');
    }
    await res.user.updateProfile({ displayName });
    //Email verification
    const actionCodeSettings = {
      url: `${process.env.VUE_APP_HOST_NAME}/login/?email=${email}`,
    };
    await res.user.sendEmailVerification(actionCodeSettings);
    error.value = null;
    isPending.value = false;
    return res;
  } catch (err) {
    console.log("useSignUp: ", err.message);
    error.value = err.message;
    isPending.value = false;
  }
};

const useSignUp = () => {
  return { error, signUp, isPending };
};

export default useSignUp;