import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/auth/Login.vue';
import SignUp from '../views/auth/SignUp.vue';
import getUser from "@/composables/getUser";
import useLogout from "@/composables/useLogout";

// route guard 
const requireAuth = (to, from, next) => {
  const { user, issuer } = getUser();
  const loginPath = window.location.pathname;
  if (!user.value) {
    next({ name: 'Login', query: { from: loginPath } });
  } else {
    if (!issuer.value) {
      const { logout } = useLogout();
      logout().then(() => {
        console.log("Logging out the non-issuer user");
        next({ name: 'Login', query: { from: loginPath } });
      });
    } else {
      next();
    }
  }
};


const requireNoAuth = async (to, from, next) => {
  const { user, issuer } = getUser();
  if (user.value) {
    if (issuer.value) {
      next({ name: 'Offerings' });
    } else {
      const { logout } = useLogout();
      logout().then(() => {
        console.log("Logging out the non-issuer user");
        next({ name: 'Login' });
      });
    }
  } else {
    next();
  }
};


const routes = [
  {
    path: '/investors',
    name: 'Investors',
    component: () => import('../views/Investors.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/offerings',
    name: 'Offerings',
    component: () => import('../views/offerings/Offerings.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/tags/:tag',
    name: 'OfferingsTag',
    component: () => import('../views/offerings/OfferingsTag.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/offerings/create',
    name: 'CreateOffering',
    component: () => import('../views/offerings/CreateOffering.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/offering/details/:id',
    name: 'OfferingDetails',
    component: () => import('../views/offerings/OfferingDetails.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/team/:id',
    name: 'OfferingTeam',
    component: () => import('../views/offerings/team/OfferingTeam.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/team/:id/create',
    name: 'CreateTeamMember',
    component: () => import('../views/offerings/team/CreateTeamMember.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/team/:id/edit/:teamMemberId',
    name: 'EditTeamMember',
    component: () => import('../views/offerings/team/EditTeamMember.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/documents/:id',
    name: 'OfferingDocuments',
    component: () => import('../views/offerings/documents/OfferingDocuments.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/documents/:id/create',
    name: 'CreateDocument',
    component: () => import('../views/offerings/documents/CreateDocument.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/documents/:id/cd',
    name: 'CreateDisclosure',
    component: () => import('../views/offerings/documents/CreateDisclosure.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/documents/:id/edit/:documentId',
    name: 'EditDocument',
    component: () => import('../views/offerings/documents/EditDocument.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/pitch/:id',
    name: 'OfferingPitch',
    component: () => import('../views/offerings/OfferingPitch.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/updates/:id',
    name: 'OfferingUpdates',
    component: () => import('../views/offerings/updates/OfferingUpdates.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/updates/:id/create',
    name: 'CreateUpdate',
    component: () => import('../views/offerings/updates/CreateUpdate.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/updates/:id/edit/:updateId',
    name: 'EditUpdate',
    component: () => import('../views/offerings/updates/EditUpdate.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/press/:id',
    name: 'OfferingPress',
    component: () => import('../views/offerings/press/OfferingPress.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/press/:id/create',
    name: 'CreatePress',
    component: () => import('../views/offerings/press/CreatePress.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/press/:id/edit/:pressId',
    name: 'EditPress',
    component: () => import('../views/offerings/press/EditPress.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/comments/:id',
    name: 'OfferingComments',
    component: () => import('../views/offerings/comments/OfferingComments.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/comments/:id/reply/:commentId',
    name: 'ReplyComment',
    component: () => import('../views/offerings/comments/ReplyComment.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/investments/:id',
    name: 'OfferingInvestments',
    component: () => import('../views/offerings/investments/OfferingInvestments.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/investment/:offeringId/details/:investmentId',
    name: 'InvestmentDetails',
    component: () => import('../views/offerings/investments/InvestmentDetails.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/Account.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/login',
    alias: '/',
    name: 'Login',
    component: Login,
    beforeEnter: requireNoAuth,
  },
  {
    path: '/signUp',
    name: 'SignUp',
    component: SignUp,
    beforeEnter: requireNoAuth,
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('@/views/Welcome.vue'),
    beforeEnter: requireNoAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      console.log("No Saved Position");
      const position = {};
      position.el = to.hash;
      position.top = 80;
      if (document.querySelector(to.hash)) {
        console.log("Query Selector: to.hash:", to.hash);
        return position;
      }
    }
    if (savedPosition) {
      console.log("Saved Position! ToHash:", to.hash);
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

export default router;
