import { ref } from 'vue';
import { projectFirestore } from '../firebase/config';

const getCollection = (collection, queries, order, limit = 0) => {

  const documents = ref(null);
  const error = ref(null);
  const isLoading = ref(true);

  // register the firestore collection reference
  let collectionRef = projectFirestore.collection(collection);

  //Handle multiple queries
  if (queries) {
    queries.forEach(query => {
      collectionRef = collectionRef.where(...query);
    });
  }
  if (order) {
    // register the firestore collection reference
    collectionRef = collectionRef.orderBy(...order);
  }

  if (limit > 0) {
    collectionRef = collectionRef.limit(limit);
  }

  collectionRef.get().then((querySnapshot) => {
    let results = [];
    querySnapshot.forEach((doc) => {
      results.push({ ...doc.data(), id: doc.id });
    });
    // update values
    documents.value = results;
    error.value = null;
    isLoading.value = false;
  }, err => {
    console.log(err.message);
    documents.value = null;
    error.value = 'could not fetch the data';
    isLoading.value = false;
  });

  return { isLoading, error, documents };
};

export default getCollection;